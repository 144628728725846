import { __decorate } from "tslib";
import { Component, Ref, Vue } from 'vue-property-decorator';
import { Inject } from '@plugin/inversify';
import { Screen } from '@component/Screen';
import { AccountForm } from '../../../../components/AccountForm';
import { UserRepositoryType } from '../../../../contracts/repositories';
/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
let ChangeAvatarView = class ChangeAvatarView extends Vue {
    constructor() {
        super(...arguments);
        this.formName = 'setAvatarForm';
        this.successMessage = '';
        /**
         * Object that collects User input.
         */
        this.avatar = null;
        /**
         * Determines whether the component is currently performing any async actions.
         */
        this.isActing = false;
        /**
         * Preview of the uploaded file
         */
        this.image = '';
    }
    /**
     * Name of the chosen file.
     */
    get filename() {
        if (this.avatar === null) {
            return this.$t('_.none');
        }
        return this.avatar.name;
    }
    /**
     * Handles the `@cancel` event on the `<AccountForm>` component.
     */
    onCancel() {
        this.$router.push({ name: 'profile.my-data' });
    }
    /**
     * Handles the `@click` event on the "browse" button.
     */
    onBrowseBtnClick() {
        this.fileInput.click();
    }
    /**
     * Handles the `@input` event on the `<input type="file">` element.
     */
    onFileInputChange() {
        if (this.fileInput.files === null) {
            return;
        }
        this.avatar = this.fileInput.files[0];
        this.image = URL.createObjectURL(this.fileInput.files[0]);
    }
    /**
     * Handles the `@submit` event on the `<AccountForm>` component.
     */
    onSubmit() {
        this.isActing = true;
        this.successMessage = '';
        if (!this.avatar) {
            return;
        }
        this.userRepository.setAvatar(this.avatar)
            .then(() => {
            this.successMessage = `${this.$t('modules.profile.views.change-password.success')}`;
        })
            .catch(error => this.$logger(error, 'warn'))
            .finally(() => {
            this.isActing = false;
        });
    }
};
__decorate([
    Ref('fileInput')
], ChangeAvatarView.prototype, "fileInput", void 0);
__decorate([
    Inject(UserRepositoryType)
], ChangeAvatarView.prototype, "userRepository", void 0);
ChangeAvatarView = __decorate([
    Component({ name: 'ChangeAvatarView', components: { AccountForm, Screen } })
], ChangeAvatarView);
export { ChangeAvatarView };
export default ChangeAvatarView;
